.orderLiveContent {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.orderLiveForm {
    display: flex;
    flex-direction: column;
    margin: auto;
}

.orderLiveFormInput {
    margin-bottom: 15px;
    border-radius: 5%;
    border: 1px solid #0D4874;
    min-width: 260px;
}

.orderLiveFormInput:focus {
    border: 3px solid #0D4874;
    margin-bottom: 13px;
    margin-right: -2px;
}

.windowElement {
    position: fixed;
    right: 0;
    margin-right: 90px;
    margin-top: 90px;
    z-index: 10;
    height: auto;
    width: 300px;
    background: white;
    border-radius: 8px;
}

.topPartWindow {
    background: #0D4874;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
}

.orderLiveItem {
    display: flex;
    flex-direction: row;
    color: #19242f;
    justify-content: space-between;
    align-items: center;
}

@media (max-width: 1070px) {
    .orderLiveContent {
        width: 95%;
        padding-left: 30px;
        align-items: start;
    }
    .orderLiveForm {
        align-self: start;
        margin: 0;
    }
}

@media (max-width: 710px) {
    .orderLiveContent {
        width: 100%;
        padding-left: 0;
        align-items: center;
    }
    .windowElement {
        position: unset;
        margin-left: auto;
        margin-right: auto;
        margin-top: 30px;
        margin-bottom: auto;
    }
    .orderLiveForm {
        align-self: center;
    }
}

@media (max-width: 310px) {
    .windowElement {
        width: 270px;
    }
}