.biographyPage {
    width: 100%;
    padding: 30px 0px;
    display: flex;
    justify-content: center;
}

.biographyPageContent {
    width: 85%;
    display: flex;
    flex-direction: row;
}

.biographyText {
    width: 50%;
    margin-left: 40px;
    /* height: 417px; */
    height: auto;
    /* overflow-y: scroll; */
    /* background-color: white; */
    display: flex;
    flex-direction: column;
    font-weight: 600;
}


.biographyBasic {
    /* height: 372px; */
    /* min-width: 280px;
    max-width: 280px; */
    width: 50%;
    height: 540px;
    background: white;
    border-radius: 5%;
}
  
  .biographySlider {
    width: 100%;
    display: flex;
  }
  
  .biographySlide {
    /* height: 250px; */
    height: 490px;
    width: 100%;
    /* height: auto; */
    border-top-left-radius: 5%;
    border-top-right-radius: 5%;
  }
  
.arrowDiw {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: -35px;
}

  .arrow {
    width: 30px;
    height: 30px;
    color: white;
    text-align: center;
    cursor: pointer;
    z-index: 2;
  }

  .biographyName {
    color: #0d559d;
    text-align: center;
    font-size: 18px;
}

.biographyDateDiv {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 75%;
    margin-left: 5%;
    height: 35px;
}

.biographyPlaceDiv {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 84.5%;
    margin-left: 5%;
    height: 35px;
}

@media (max-width: 800px) {
    .biographyPageContent {
        flex-direction: column;
    }
    .biographyText {
        margin-left: 0;
        margin-top: 30px;
        width: 100%;
    }
    .biographyBasic {
        margin: auto;
        width: 100%;
        height: auto;
    }
    .biographySlide {
        height: 372px;
    }
  }

@media (max-width: 330px) {
    .biographyPageContent {
        width: 90%;
    }
    .biographyBasic {
        min-width: 250px;
    }
    .biographyDateDiv {
        width: 90%;
    }
    .biographyPlaceDiv {
        width: 96%;
        margin-left: 2%;
    }
  }