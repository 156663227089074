.popup {
    opacity: 0;
    pointer-events: none;
    position: fixed;
    left: 50%;
    top: 5%;
    transform: translate(-50%, -50%);
    background-color: #0D4874;
    color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    transition: opacity 0.3s ease, transform 0.3s ease;
    transform: translate(-50%, -50%);
}

.popup.show {
    opacity: 1;
    pointer-events: auto;
    transform: translate(-50%, -50%) scale(1);
}

.popup-content {
    text-align: center;
}

@keyframes fadeInOut {
    0% {
        opacity: 0;
    }
    10% {
        opacity: 1;
    }
    90% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.selectedBookContent {
    width: 98%;
    display: flex;
    flex-direction: column;
    padding: 30px 0px;
}

.selectedBookTopContent {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.leftSelectedBook {
    width: 40%;
    height: auto;
    background-color: #eeeeee;
    display: flex;
    flex-direction: row;
    border-radius: 5%;
}

.selectedBookImage {
    width: 45%;
    height: auto;
    border-radius: 5%;
}

.selectedBookBasicContent {
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 55%;
}

.leftTopSelectedBook {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 50%;
    width: 100%;
}

.selectedBookAuthor {
    font-size: 14px;
    color: #a2a2a2;
    font-weight: 600;
}

.selectedBookName {
    font-size: 24px;
    color: #19242f;
    font-weight: 600;
    margin: 0;
}

.leftBottomSelectedBook {
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: end;
}

.selectedBookPriceTitle {
    font-weight: 600;
    color: #19242f;
    display: flex;
    justify-content: end;
    margin-bottom: 5px;
    margin-right: 15px;
}

.selectedBookPrice {
    font-weight: bold;
    font-size: 18px;
    color: #19242f;
    display: flex;
    justify-content: end;
    margin-top: 0;
    margin-right: 15px;
}

.addInBasketButton {
    font-size: 16px;
    padding: 12px 24px;
    min-width: 181px;
    max-width: 215px;
    height: 48px;
    background-color: #0d4874;
    color: white;
    border-bottom-right-radius: 20%;
    cursor: pointer;
    transition: background-color 0.5s ease;
}

.addInBasketButton:hover {
    background-color:  #0d559d;
}

.rightSelectedBook {
    width: 35%;
    margin-left: 80px;
    max-height: 302px;
    overflow-y: scroll;
}

.selectedBookBottomContent {
    padding-top: 35px;
    width: 60%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

/* style for modal */

.modalContent {
    background-color: white;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    opacity: 0; 
    transition: opacity 0.5s ease;
    align-items: center;
    color: #19242f;
    font-weight: 600;
}

.showModal {
    opacity: 1;
    position: fixed;
    bottom: 0;
    left: 0;
}

.modalAddButton {
  color: white;
  background-color: #0d4874;
  border: none;
  text-transform: uppercase;
  letter-spacing: 0.14rem;
  padding: 0.75rem 1.5rem;
  cursor: pointer;
  line-height: 1rem;
  font-size: 0.625rem;
  font-weight: 600;
  min-width: 3rem;
  transition: background-color 0.5s ease;
  margin-bottom: 15px;
}

.modalAddButton:hover {
  background-color:  #0d559d;
}

.closeModalIcon {
    cursor: pointer;
}

@media (max-width: 940px) {
    .selectedBookTopContent {
        flex-direction: column;
        width: 80%;
        margin: auto;
    }
    .leftSelectedBook {
        width: auto;
        height: 400px;
    }
    .rightSelectedBook {
        width: 100%;
        margin-left: 0;
        margin-top: 10px;
    }
  }

  @media (max-width: 660px) {
    .leftSelectedBook {
        width: auto;
        height: 300px;
    }
  }

  @media (max-width: 500px) {
    .leftSelectedBook {
        width: 238px;
        height: auto;
        flex-direction: column;
        margin: auto;
    }
    .selectedBookImage {
        width: 100%;
    }
    .selectedBookBasicContent {
        width: 91%;
    }
    .closeModalIcon {
        display: none;
    }
    .selectedBookBottomContent {
        width: 80%;
    }
  }