.mobileHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #0d559d; 
    color: white;
    padding: 10px 20px;
    overflow-x: hidden;
}

#mobileLogo {
    width: 180px;
    transition: transform 1s 0s;
}

.hidden {
    transform: translateX(100vw); 
    transition: 0.5s ease-out;
}
  
.visible {
    opacity: 1;
    transition: 0.5s ease-out;
}

.rightDiv {
    display: flex;
    flex-direction: row;
}

.backgroundPhoneMobile {
    border-radius: 50%;
    background-color: #467abf;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.closeDiv {
    border-radius: 50%;
    background-color: #467abf;
    width: 36px;
    height: 36px;
    display: none;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 1px solid #467abf;
    position: absolute;
    left: 50%;
    z-index: 10;
}

.closeDiv:hover {
    background-color: #0d559d;
    border: 1px solid #467abf;
}

.sidebar {
    height: 100%;
    width: 0;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #0d559d;
    overflow-x: hidden;
    transition: 0.5s;
    z-index: 10;
}

.sidebar a {
    display: block; 
    transition: 0.3s;
    color: lightblue;
    text-decoration: none;
    font-size: 14px;
}

.sidebar a:hover {
    color: #f1f1f1;
}

.sidebar.open {
    width: 50%;
}

.mobileLogo.open {
    display: none;
    transition: 0.5s;
} 

.sidebarContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-top: 20px;
}

.menuMobileLogo {
    width: 140px;
    margin: auto;
}

.mobileNav {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 25px;
    width: 80%;
}

.mobileNav a {
    color: lightblue;
    text-decoration: none;
    font-size: 14px;
    transition: 0.3s;
    display: flex;
    justify-content: center;
    height: 50px;
}

@media (max-width: 330px) {
    .mobileHeader {
      padding: 10px 5px;
    }
}

@media (max-width: 290px) {
    .mobileHeader {
      padding: 10px 0px;
    }
}