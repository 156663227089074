/* Header container styles */
.header {
    display: flex;
    flex-direction: column;
    background-color: #0d559d; 
    color: white;
    padding: 10px 0px;
  }
  
.top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
    margin-bottom: 15px;
    padding: 0px 20px;
}

.contact {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.logo {
  margin-right: 60px;
}

.backgroundPhone {
    border-radius: 50%;
    background-color: #467abf;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.backgroundBasket {
    border-radius: 50%;
    background-color: #467abf;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 1px solid #467abf;
    margin-left: 10%;
}

.backgroundBasket:hover {
    background-color: #0d559d;
    border: 1px solid #467abf;
}

.bottom {
    width: 75%;
    height: 30px;
    margin: auto;
    display: flex;
    align-items: center;
}
  /* Navigation links styles */
  .nav {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .nav a {
    color: lightblue;
    text-decoration: none;
    font-size: 14px;
    transition: 0.3s;
  }
  
  .nav a:hover {
    color: white;
  }

