.popup {
    opacity: 0;
    pointer-events: none;
    position: fixed;
    left: 50%;
    top: 5%;
    transform: translate(-50%, -50%);
    background-color: #0D4874;
    color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    transition: opacity 0.3s ease, transform 0.3s ease;
    transform: translate(-50%, -50%);
}

.popup.show {
    opacity: 1;
    pointer-events: auto;
    transform: translate(-50%, -50%) scale(1);
}

.popup-content {
    text-align: center;
}

@keyframes fadeInOut {
    0% {
        opacity: 0;
    }
    10% {
        opacity: 1;
    }
    90% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.cartReviewContent {
    width: 98%;
    display: grid;
    margin: auto;
    padding: 30px 0px;
    grid-template-columns: repeat(3, 1fr);
    gap: 25px;
}

.cartBookItem {
    width: 90%;
    height: auto;
    background-color: #eeeeee;
    display: flex;
    flex-direction: row;
    align-items: end;
    border-radius: 5%;
    margin: auto;
}

.cartBookImage {
    width: 45%;
    height: auto;
    border-radius: 5%;
}

.cartBookBasicContent {
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 55%;
}

.cartActionDiv {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top: 1px solid #0D4874;
}

.onAdressOrEBookDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 24px;
    color: #19242f;
}

.emptyBasketDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 43.4vh;
    font-size: 28px;
    color: #19242f;
}

@media (max-width: 1320px) {
    .cartReviewContent {
        grid-template-columns: repeat(2, 1fr);
    }
  }

@media (max-width: 900px) {
    .cartBookItem {
        height: auto;
        width: 260px;
        flex-direction: column;
    }
    .cartBookImage {
        width: 100%;
        height: 60%;
    }
    .cartBookBasicContent {
        width: 91%;
    }
  }

  @media (max-width: 580px) {
    .cartReviewContent {
        grid-template-columns: repeat(1, 1fr);
    }
  }

