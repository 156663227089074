.searchComponent {
  height: auto;
  width: 477px;
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5;
  margin-top: -40px;
  margin-left: 50px;
}

.searchTop {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 76px;
  box-shadow: -3px 0 5px #C2C2C2, 3px 0 5px #C2C2C2, 0 5px 5px #C2C2C2;
  background-color: white;
}

.leftTop {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.searchIcon {
  margin-left: 10px;
  margin-right: 10px;
  color: #575757;
}

input {
  border: none;
  width: 272px;
  height: 72px;
  margin-right: 5px;
}

input:focus {
  outline: none;
}

input::-webkit-input-placeholder {
  color: #575757;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 0.03rem;
}

.searchButton {
  color: white;
  background-color: #0d4874;
  border: none;
  text-transform: uppercase;
  letter-spacing: 0.14rem;
  padding: 0.75rem 1.5rem;
  cursor: pointer;
  line-height: 2.2rem;
  font-size: 0.625rem;
  font-weight: 600;
  min-width: 3rem;
  transition: background-color 0.5s ease;
  margin-right: 15px;
}

.searchButton:hover {
  background-color: #0d559d;
}

.cancelSearchButton {
  color: white;
  background-color: #740316;
  border: none;
  text-transform: uppercase;
  letter-spacing: 0.14rem;
  padding: 0.75rem 1.5rem;
  cursor: pointer;
  line-height: 1rem;
  font-size: 0.625rem;
  font-weight: 600;
  min-width: 3rem;
  transition: background-color 0.5s ease;
  margin-right: 15px;
}

.cancelSearchButton:hover {
  background-color:  hsl(9, 58%, 35%);
}
  
  .suggestions {
    margin-top: 0;
    align-self: flex-start;
    list-style: none;
    max-height: 120px;
    overflow-y: auto;
    padding-left: 0;
    width: 275px;
    margin-left: 54px;
  }
  
  .suggestions li {
    padding: 0.5rem;
  }
  
  .suggestion-active,
  .suggestions li:hover {
    background-color: #0d559d;
    color: white;
    cursor: pointer;
    font-weight: 700;
  }
  
  .suggestions li:not(:last-of-type) {
    border-bottom: 1px solid white;
  }

@media (max-width: 1000px) {
  .searchComponent {
    margin-left: 0;
  }
}

@media (max-width: 500px) {
  .searchComponent {
    width: 80%;
  }
  .searchButton {
    display: none;
  }
  .cancelSearchButton {
    display: none;
  }
  .leftTop {
    width: 100%;
  }
  input {
    width: 82%;
  }
  .suggestions {
    width: 100%;
    margin-left: 0;
  }
}