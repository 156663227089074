.galleryPageContent {
    width: 98%;
    height: 420px;
    padding: 30px 0px;
    gap: 25px;    
    display: flex;
    overflow-x: scroll;
    align-self: center;
    /* flex-wrap: wrap; */
}

.galleryImageDiv {
    min-width: 260px;
    min-height: 395px;
    height: auto;
    width: auto;
}

.galleryPageContentSmaller {
    width: 98%;
    height: 280px;
    padding: 30px 0px;
    gap: 25px;    
    display: flex;
    overflow-x: scroll;
    padding: "10px 20px";
    align-self: center;
    /* flex-wrap: wrap; */
}

.galleryImageDivSmaller {
    min-width: 270px;
    height: 260px;
}

.galleryImage {
    width: 100%;
    height: 100%;
}