.welcomePage {
    width: 100%;
}

.belowHeaderBackground {
    width: 100%;
    /* background-color: #2f4367; */
    background-image: url('../../../assets/images/landingPage/belowHeaderBigImg.jpg');
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 218px;
}

.belowHeaderImg {
    min-width: 280px;
}

.welcomePageContent {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.welcomePageTitle {
    text-transform: uppercase;
    color: #252e39;
    font-size: 30px;
}

.welcomePageBooksRow {
    display: flex;
    flex-direction: row;
    width: 98%;
    justify-content: space-between;
    margin: auto;
    opacity: 0; 
    transition: opacity 0.5s ease;
    align-items: center;
}

.showBooksRow {
    opacity: 1;
}

/* search result books row */
.searchWelcomePageRow {
    display: flex;
    flex-wrap: wrap;
    width: 98%;
    justify-content: center;
    margin: auto;
    transition: opacity 0.5s ease;
    align-items: center;
    margin-top: -20px;
}

.arrowIcon {
    border-radius: 50%;
    background-color: #467abf;
    width: 34px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 1px solid #467abf;
    color: white;
}

.arrowIcon:hover {
    background-color: #0d559d;
    border: 1px solid #467abf;
}

.welcomePageBook {
    height: 420px;
    width: 238px;
    display: flex;
    flex-direction: column;
    background-color: white;
    cursor: pointer;
    border-radius: 5%;
    transition: transform 0.5s ease;
}

.welcomePageBook:hover {
    transform: scale(1.1);
}

/* search result book */
.searchWelcomePageBook {
    height: 420px;
    width: 238px;
    display: flex;
    flex-direction: column;
    background-color: white;
    cursor: pointer;
    border-radius: 5%;
    transition: transform 0.5s ease;
    margin-right: 15px;
    margin-top: 20px;
    margin-left: 15px;
}

.searchWelcomePageBook:hover {
    transform: scale(1.1);
}

.bookImage {
    width: 234px;
    height: 354px;
    border-radius: 5%;
}

.bookTitle {
    display: flex;
    justify-content: center;
    font-size: 16px;
    color: #252e39;
    margin-top: 10px;
    margin-bottom: 3px;
}

.bookPrice {
    display: flex;
    justify-content: center;
    font-size: 21px;
    color: #740316;
}

@media (max-width: 490px) {
    .belowHeaderBackground {
        background-image: url('../../../assets/images/landingPage/belowHeaderImage.jpg');
    }
  }